const Occasions = {
    occasion: [
        {
            id: 1,
            name: "Urodziny",
            ageFrom: 1,
            ageTo: 100,
            relations: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36],
            gender: ["male", "female"],
            dayFrom: 1,
            monthFrom: 1,
            dayTo: 31,
            monthTo: 12,
        },
        {
            id: 2,
            name: "Imieniny",
            ageFrom: 1,
            ageTo: 100,
            relations: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36],
            gender: ["male", "female"],
            dayFrom: 1,
            monthFrom: 1,
            dayTo: 31,
            monthTo: 12,
        },
        {
            id: 3,
            name: "Ślub",
            ageFrom: 18,
            ageTo: 100,
            relations: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36],
            gender: ["male", "female"],
            dayFrom: 1,
            monthFrom: 1,
            dayTo: 31,
            monthTo: 12,
        },
        {
            id: 4,
            name: "Chrzciny",
            ageFrom: 1,
            ageTo: 3,
            relations: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36],
            gender: ["male", "female"],
            dayFrom: 1,
            monthFrom: 1,
            dayTo: 31,
            monthTo: 12,
        },
        {
            id: 5,
            name: "Roczek",
            ageFrom: 1,
            ageTo: 1,
            relations: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36],
            gender: ["male", "female"],
            dayFrom: 1,
            monthFrom: 1,
            dayTo: 31,
            monthTo: 12,
        },
        {
            id: 6,
            name: "Kawalerski",
            ageFrom: 18,
            ageTo: 35,
            relations: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36],
            gender: ["male"],
            dayFrom: 1,
            monthFrom: 1,
            dayTo: 31,
            monthTo: 12,
        },
        {
            id: 7,
            name: "Panieński",
            ageFrom: 18,
            ageTo: 35,
            relations: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36],
            gender: ["female"],
            dayFrom: 1,
            monthFrom: 1,
            dayTo: 31,
            monthTo: 12,
        },
        {
            id: 8,
            name: "18 urodziny",
            ageFrom: 18,
            ageTo: 18,
            relations: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36],
            gender: ["male", "female"],
            dayFrom: 1,
            monthFrom: 1,
            dayTo: 31,
            monthTo: 12,
        },
        {
            id: 9,
            name: "Rocznica ślubu",
            ageFrom: 19,
            ageTo: 100,
            relations: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36],
            gender: ["male", "female"],
            dayFrom: 1,
            monthFrom: 1,
            dayTo: 31,
            monthTo: 12,
        },
        {
            id: 10,
            name: "Inna okazja lub brak",
            ageFrom: 1,
            ageTo: 100,
            relations: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36],
            gender: ["male", "female"],
            dayFrom: 1,
            monthFrom: 1,
            dayTo: 31,
            monthTo: 12,
        },
        {
            id: 11,
            name: "Boże narodzenie",
            ageFrom: 1,
            ageTo: 100,
            relations: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36],
            gender: ["male", "female"],
            dayFrom: 10,
            monthFrom: 10,
            dayTo: 26,
            monthTo: 12,
        },
        {
            id: 12,
            name: "Mikołajki",
            ageFrom: 3,
            ageTo: 15,
            relations: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36],
            gender: ["male", "female"],
            dayFrom: 10,
            monthFrom: 10,
            dayTo: 6,
            monthTo: 12,
        },
        {
            id: 13,
            name: "Dzień dziecka",
            ageFrom: 3,
            ageTo: 18,
            relations: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36],
            gender: ["male", "female"],
            dayFrom: 1,
            monthFrom: 5,
            dayTo: 1,
            monthTo: 6,
        },
        {
            id: 14,
            name: "Halloween",
            ageFrom: 5,
            ageTo: 15,
            relations: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36],
            gender: ["male", "female"],
            dayFrom: 15,
            monthFrom: 9,
            dayTo: 31,
            monthTo: 10,
        },
        {
            id: 15,
            name: "Wielkanoc",
            ageFrom: 3,
            ageTo: 100,
            relations: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36],
            gender: ["male", "female"],
            dayFrom: 1,
            monthFrom: 3,
            dayTo: 25,
            monthTo: 4,
        },
        {
            id: 16,
            name: "Komunia",
            ageFrom: 8,
            ageTo: 10,
            relations: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36],
            gender: ["male", "female"],
            dayFrom: 1,
            monthFrom: 3,
            dayTo: 31,
            monthTo: 8,
        },
        {
            id: 17,
            name: "Dzień chłopaka",
            ageFrom: 5,
            ageTo: 100,
            relations: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36],
            gender: ["male"],
            dayFrom: 1,
            monthFrom: 9,
            dayTo: 30,
            monthTo: 9,
        },
        {
            id: 18,
            name: "Dzień kobiet",
            ageFrom: 5,
            ageTo: 100,
            relations: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36],
            gender: ["female"],
            dayFrom: 10,
            monthFrom: 2,
            dayTo: 8,
            monthTo: 3,
        },
        {
            id: 19,
            name: "Dzień Taty",
            ageFrom: 18,
            ageTo: 100,
            relations: [2, 3],
            gender: ["male"],
            dayFrom: 10,
            monthFrom: 5,
            dayTo: 23,
            monthTo: 6,
        },
        {
            id: 20,
            name: "Dzień Mamy",
            ageFrom: 18,
            ageTo: 100,
            relations: [1, 3],
            gender: ["female"],
            dayFrom: 20,
            monthFrom: 4,
            dayTo: 26,
            monthTo: 5,
        },
        {
            id: 21,
            name: "Dzień dziadka",
            ageFrom: 40,
            ageTo: 100,
            relations: [6],
            gender: ["male"],
            dayFrom: 1,
            monthFrom: 1,
            dayTo: 22,
            monthTo: 1,
        },
        {
            id: 22,
            name: "Dzień babci",
            ageFrom: 40,
            ageTo: 100,
            relations: [7],
            gender: ["female"],
            dayFrom: 1,
            monthFrom: 1,
            dayTo: 21,
            monthTo: 1,
        },
        {
            id: 23,
            name: "Walentynki",
            ageFrom: 12,
            ageTo: 100,
            relations: [18, 19, 20, 21, 22, 23, 28, 29],
            gender: ["male", "female"],
            dayFrom: 10,
            monthFrom: 1,
            dayTo: 14,
            monthTo: 2,
        },
        {
            id: 24,
            name: "Dzień nauczyciela",
            ageFrom: 18,
            ageTo: 100,
            relations: [30, 31],
            gender: ["male", "female"],
            dayFrom: 10,
            monthFrom: 9,
            dayTo: 14,
            monthTo: 10,
        }
    ]
};


export default Occasions;