import React from 'react';
import PropTypes from 'prop-types';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import "./button-upload.css";

const ButtonUploadImage = ({ typeImage, onChange }) => {

  const inputKey = 'upload-product-image-' + typeImage;

  return (
    <div className="upload-image-holder">
      <span className='addImgInfo'>Max 2MB</span>
      <input accept="image/*" id={inputKey} multiple={typeImage !== "thumbnail"} type="file" className="hide-upload-input" onChange={(e) => onChange && onChange(e)} />
      <span className="add-icon">
        <label htmlFor={inputKey}>
          <AddCircleOutlineIcon fontSize="large" color="primary" />
        </label>
      </span>
    </div>
  );
}

ButtonUploadImage.propTypes = {
  typeImage: PropTypes.string,
  onChange: PropTypes.func,
}

export default ButtonUploadImage; 