import Home from "./pages/home/Home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProductList from "./pages/productList/ProductList";
import RelationList from "./pages/relationList/RelationList";
import OccasionList from "./pages/occasionList/OccasionList";
import CategoryList from "./pages/categoryList/CategoryList";
import SubCategoryList from "./pages/subCategoryList/SubCategoryList";
import NotFound from "./pages/notFound/NotFound";
import ProductForm from "./pages/productForm/ProductForm";
import Login from "./pages/login/Login";
import BoxDecoder from "./pages/boxDecoder/BoxDecoder";
import { useSelector } from "react-redux";
import BoxDecoder2 from "./pages/boxDecoder2/BoxDecoder2";



function App() {
  const { currentUser } = useSelector((state) => state.user);
  return (

    <Router>
      <Routes>
        {currentUser?.isAdmin ? (
          <>
            <Route exact path="/" element={<Home />} />
            <Route path="/products" element={<ProductList />} />
            <Route path="/product" element={<ProductForm />} />
            <Route path="/product/:productId" element={<ProductForm />} />
            <Route path="/relations" element={<RelationList />} />
            <Route path="/occasions" element={<OccasionList />} />
            <Route path="/categories" element={<CategoryList />} />
            <Route path="/subcategories" element={<SubCategoryList />} />
            <Route path="/decoder" element={<BoxDecoder />} />
            <Route path="/decoder2" element={<BoxDecoder2 />} />
            <Route path="*" element={<NotFound />} />
          </>
        ) :
          <Route exact path="*" element={currentUser?.isAdmin ? <Home /> : <Login />} />
        }
      </Routes >
    </Router >
  );
}

export default App;
