import axios from "axios";

//const BASE_URL = "http://localhost:5000/";
const BASE_URL = "https://www.api.herobox.pl/";
let TOKEN = "";

if (sessionStorage.getItem("persist:root") !== null) {
    TOKEN = JSON.parse(JSON.parse(sessionStorage.getItem("persist:root")).user).currentUser?.accessToken;
};



export const publicRequest = axios.create({
    baseURL: BASE_URL,
});

export const userRequest = axios.create({
    baseURL: BASE_URL,
    headers: { token: `Bearer ${TOKEN}` },
});