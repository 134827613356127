import "./home.css";
import Sidebar from "../../components/sidebar/Sidebar";

export default function Home() {
  return (
    <div className="container">
      <Sidebar />
      <div className="content">
        <h1 className="home-text">
          HEROBOX - Admin Panel
          STORNA STARTOWA
        </h1>
      </div>
    </div >
  );
}
