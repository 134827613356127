import "./notFound.css";
import Sidebar from "../../components/sidebar/Sidebar";

export default function NotFound() {
    return (
        <div className="container">
            <Sidebar />
            <div className="content">
                <h1 className="notfound-text">
                    STRONA NIE ISTNIEJE
                </h1>
            </div>
        </div >
    );
}
