import { useState } from "react";
import { DataGrid } from '@mui/x-data-grid';
import Sidebar from "../../components/sidebar/Sidebar";
import "./categoryList.css";
import Categories from "../../box_info/Categories"

export default function CategoryList() {
  const [pageSize, setPageSize] = useState(20);
  const categories = Categories.category;

  const columns = [
    { field: "id", headerName: "ID", flex: 0.1 },
    {
      field: "name",
      headerName: "Kategoria",
      flex: 0.5,
    },
    {
      field: "ageFrom",
      headerName: "Wiek od",
      flex: 0.1,
    },
    {
      field: "ageTo",
      headerName: "Wiek do",
      flex: 0.1,
    },
    {
      field: "gender",
      headerName: "Płeć",
      flex: 0.2,
      renderCell: (params) => {
        return (
          <div>
            {params.row.gender.map(g => (g === "male" ? <span key={g}>Mężczyzna; </span> : <span key={g}>Kobieta</span>))}
          </div>
        );
      },
    },
    {
      field: "occasions",
      headerName: "ID okazji",
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            {params.row.occasions.map(o => (<span key={o}>{o}; </span>))}
          </div>
        );
      },
    }
  ];

  return (
    <div className="container">
      <Sidebar />
      <div className="categoryList">
        <h1 className="categoryTitle">Kategorie</h1>
        <div>
          <DataGrid
            rows={categories}
            disableSelectionOnClick
            autoHeight
            columns={columns}
            getRowId={row => row.id}
            pageSize={pageSize}
            rowsPerPageOptions={[10, 20, 50, 100]}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          />
        </div>
      </div>
    </div>
  );
}
