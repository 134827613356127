import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from 'notistack';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ButtonUploadImage from "../../components/button-upload/ButtonUpload";
import {
  TextField,
  FormControlLabel,
  Checkbox, Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  OutlinedInput,
  InputLabel,
  MenuItem,
  ListItemText,
  Select,
  FormControl,
  Box,
  Slider,
  Switch
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import "./productForm.css";

import Sidebar from "../../components/sidebar/Sidebar";

import Relations from "../../box_info/Realtions";
import Occasions from "../../box_info/Occasions";
import Categories from "../../box_info/Categories";
import SubCategories from "../../box_info/SubCategories";
import { addInventoryProduct, getInventoryProductsData } from "../../redux/apiCalls";

function agetext(age) {
  return `${age}`;
}

function fileToBase64(file, func) {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    func(reader.result);
  };
  reader.onerror = function (error) {
    console.log('Error: ', error);
  };
}


export default function ProductForm() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();
  const productId = location.pathname.split("/")[2];
  const toEdit = (productId === "" || productId === undefined) ? false : true;
  const [ready, setReady] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [excludeBox, setExcludeBox] = useState(false);
  const [productImage, setProductImage] = useState();
  const [mainInputs, setMainInputs] = useState({ name: "", sku: "", ean: "", price: "", stockQuantity: "" });
  const [relations, setRelations] = useState(Relations.relation.map(r => { return { ...r, checked: false, disabled: false }; }));
  const [occasions, setOccasions] = useState(Occasions.occasion.map(o => { return { ...o, checked: false, disabled: false }; }));
  const [categories, setCategories] = useState(Categories.category.map(c => { return { ...c, checked: false, subCats: false, disabled: false }; }));
  const [subCategories, setSubCategories] = useState(SubCategories.subCategory.map(sc => { return { ...sc, checked: false, disabled: false }; }));
  const [ageRange, setAgeRange] = useState([1, 100]);
  const [disabledRelations, setDisabledRelations] = useState(true);
  const [disabledOccasions, setDisabledOccasions] = useState(true);
  const [disabledCategories, setDisabledCategories] = useState(true);
  const [allRelationsChecked, setAllRelationsChecked] = useState(false);
  const [allOccasionsChecked, setAllOccasionsChecked] = useState(false);

  const [gender, setGender] = useState([
    { key: 0, label: 'Kobieta', name: 'female', checked: false },
    { key: 1, label: 'Mężczyzna', name: 'male', checked: false }
  ]);

  useEffect(() => {
    setReady(false)
    let parents_ids = []
    subCategories.forEach((subCat) => {
      parents_ids.push(subCat.parent_id);
    })
    categories.forEach((cat) => {
      if (parents_ids.includes(cat.id)) { cat.subCats = true }
    })
    setReady(true);
    if (toEdit) {
      setReady(false);
      fillData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    filterRelations();
    checkRelationsDisable();
    filterOccasions();
    checkOccasionsDisable();
    filterCategories();
    checkCategoriesDisable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ageRange, gender, relations, occasions, categories, subCategories, productImage]);

  const fillData = async () => {
    try {
      const res = await getInventoryProductsData(productId);
      if (res.data.status === "SUCCESS") {
        const product = res.data.products[productId];
        if (product.text_fields["extra_field_6873"] !== undefined && product.text_fields["extra_field_6873"] === "TAK") { setExcludeBox(true) } else { setExcludeBox(false) }
        let mainInputs = {
          name: product.text_fields.name,
          sku: product.sku,
          ean: product.ean,
          price: product.prices['136'],
          stockQuantity: product.stock['bl_177']
        }
        setMainInputs(mainInputs);
        if (product.text_fields['extra_field_6871'] !== undefined) {
          const herobox_params = JSON.parse(product.text_fields['extra_field_6871']);
          if (herobox_params['herobox_gender'] !== undefined && herobox_params['herobox_gender'] !== "" && herobox_params['herobox_gender'] !== false) {
            JSON.parse(herobox_params['herobox_gender']).forEach((g) => {
              gender.forEach((gend) => {
                if (gend.key === g) {
                  gend.checked = true;
                }
              })
            })
          }
          if (herobox_params['herobox_relations'] !== undefined && herobox_params['herobox_relations'] !== "" && herobox_params['herobox_relations'] !== false) {
            JSON.parse(herobox_params['herobox_relations']).forEach((r) => {
              relations.forEach((rF) => {
                if (rF.id === r) {
                  rF.checked = true;
                }
              })
            })
          }
          if (herobox_params['herobox_occasions'] !== undefined && herobox_params['herobox_occasions'] !== "" && herobox_params['herobox_occasions'] !== false) {
            JSON.parse(herobox_params['herobox_occasions']).forEach((o) => {
              occasions.forEach((oF) => {
                if (oF.id === o) {
                  oF.checked = true;
                }
              })
            })
          }
          if (herobox_params['herobox_categories'] !== undefined && herobox_params['herobox_categories'] !== "" && herobox_params['herobox_categories'] !== false) {
            JSON.parse(herobox_params['herobox_categories']).forEach((c) => {
              categories.forEach((cF) => {
                if (cF.id === c) {
                  cF.checked = true;
                }
              })
            })
          }
          if (herobox_params['herobox_subCategories'] !== undefined && herobox_params['herobox_subCategories'] !== "" && herobox_params['herobox_subCategories'] !== false) {
            JSON.parse(herobox_params['herobox_subCategories']).forEach((sC) => {
              subCategories.forEach((sCF) => {
                if (sCF.id === sC) {
                  sCF.checked = true;
                }
              })
            })
          }
          if (herobox_params['herobox_age'] !== undefined && herobox_params['herobox_age'] !== "" && herobox_params['herobox_age'] !== false) { setAgeRange(JSON.parse(herobox_params['herobox_age'])) }
        }

        if (product.images) {
          setProductImage(product.images[1]);
        }
        checkRelationsDisable();
        checkOccasionsDisable();
        checkCategoriesDisable();
        setReady(true);
      } else {
        enqueueSnackbar(res.data.status + ": " + res.data.error_code + " - " + res.data.error_message, { variant: 'error' });
        setReady(true);
      }
    } catch (error) {
      enqueueSnackbar(error, { variant: 'error' });
      setReady(true);
    }
  };

  const handleAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleToBox = (e) => {
    setExcludeBox(e.target.checked);
  };

  const handleThumbnailProduct = (file) => {
    if (file.size < 2097152) {
      fileToBase64(file, (result) => {
        setProductImage(result);
      })
    } else {
      enqueueSnackbar("Rozmiar pliku przekracza 2MB", { variant: 'error' });
    }

  }

  const handleMainInputs = (e) => {
    setMainInputs(prev => {
      return { ...prev, [e.target.id]: e.target.value }
    })
  };

  const handleGender = (e) => {
    let tmpGender = [...gender];
    tmpGender[e.target.ariaLabel].checked = e.target.checked;
    setGender(tmpGender);
  };

  const handleRelations = (e) => {
    let tmpRelations = [...relations];
    if (e.target.ariaLabel === "all-relations") {
      setAllRelationsChecked(e.target.checked);
      tmpRelations.forEach(r => {
        if (r.disabled === false) {
          r.checked = e.target.checked;
        }
      })
    } else {
      tmpRelations.filter(tR => tR.id === parseInt(e.target.ariaLabel))[0].checked = e.target.checked;
    }
    setRelations(tmpRelations);
  };

  const handleOccasions = (e) => {
    let tmpOccasions = [...occasions];
    if (e.target.ariaLabel === "all-occasions") {
      setAllOccasionsChecked(e.target.checked);
      tmpOccasions.forEach(r => {
        if (r.disabled === false) {
          r.checked = e.target.checked;
        }
      })
    } else {
      tmpOccasions.filter(tO => tO.id === parseInt(e.target.ariaLabel))[0].checked = e.target.checked;
    }
    setOccasions(tmpOccasions);
  };

  const handleCategories = (e) => {
    let tmpCategories = [...categories];
    tmpCategories.filter(tC => tC.id === parseInt(e.target.ariaLabel))[0].checked = e.target.checked;
    setCategories(tmpCategories);
  };

  const handleSubCategories = (e) => {
    let tmpSubCategories = [...subCategories];
    tmpSubCategories.forEach(sC => {
      if (categories.filter(tC => tC.id === sC.parent_id)[0].name === e.target.name) {
        if (e.target.value.includes(sC.name)) { sC.checked = true } else { sC.checked = false }
      }
    })
    setSubCategories(tmpSubCategories);
  };

  const handleAge = (event, newValue) => {
    setAgeRange(newValue);
  };

  const checkRelationsDisable = () => {
    let gCount = 0;
    gender.forEach(g => {
      if (g.checked) { gCount = gCount + 1 };
    });
    if (gCount > 0) { setDisabledRelations(false) } else { setDisabledRelations(true); relations.forEach(r => r.checked = false) };
  };

  const checkOccasionsDisable = () => {
    let rCount = 0;
    relations.forEach(r => {
      if (r.checked) { rCount = rCount + 1 };
    });
    if (rCount > 0) { setDisabledOccasions(false) } else { setDisabledOccasions(true); occasions.forEach(o => o.checked = false) };
  };

  const checkCategoriesDisable = () => {
    let oCount = 0;
    occasions.forEach(o => {
      if (o.checked) { oCount = oCount + 1 };
    });
    if (oCount > 0) { setDisabledCategories(false) } else { setDisabledCategories(true); categories.forEach(c => c.checked = false); subCategories.forEach(sc => sc.checked = false) };
  };

  const filterRelations = () => {
    relations.forEach((r) => {
      let gState = [];
      let aState = [];
      gender.forEach((g) => {
        if (g.checked) {
          if (r.gender.includes(g.name)) {
            gState.push(true);
          } else {
            gState.push(false);
          }
        }
      })
      if ((ageRange[0] < r.ageFrom && ageRange[1] < r.ageFrom) || (ageRange[0] > r.ageTo && ageRange[1] > r.ageTo)) {
        aState.push(false);
      } else {
        aState.push(true);
      }

      r.disabled = !gState.includes(true) || !aState.includes(true);
      if (r.disabled) { r.checked = false }
    });
  };

  const filterOccasions = () => {
    occasions.forEach((o) => {
      let gState = [];
      let rState = [];
      let aState = [];
      //sprawdź czy pasują płcie
      gender.forEach((g) => {
        if (g.checked) {
          if (o.gender.includes(g.name)) {
            gState.push(true);
          } else {
            gState.push(false);
          }
        }
      })
      relations.forEach((r) => {
        if (r.checked) {
          if (o.relations.includes(r.id)) {
            rState.push(true);
          } else {
            rState.push(false);
          }
        }
      })

      if ((ageRange[0] < o.ageFrom && ageRange[1] < o.ageFrom) || (ageRange[0] > o.ageTo && ageRange[1] > o.ageTo)) {
        aState.push(false);
      } else {
        aState.push(true);
      }

      o.disabled = !gState.includes(true) || !rState.includes(true) || !aState.includes(true);
      if (o.disabled) { o.checked = false }
    });
  };

  const filterCategories = () => {
    categories.forEach((c) => {
      let gState = [];
      let oState = [];
      let aState = [];
      gender.forEach((g) => {
        if (g.checked) {
          if (c.gender.includes(g.name)) {
            gState.push(true);
          } else {
            gState.push(false);
          }
        }
      })
      occasions.forEach((o) => {
        if (o.checked) {
          if (c.occasions.includes(o.id)) {
            oState.push(true);
          } else {
            oState.push(false);
          }
        }
      })

      if ((ageRange[0] < c.ageFrom && ageRange[1] < c.ageFrom) || (ageRange[0] > c.ageTo && ageRange[1] > c.ageTo)) {
        aState.push(false);
      } else {
        aState.push(true);
      }

      c.disabled = !gState.includes(true) || !oState.includes(true) || !aState.includes(true);
      if (c.disabled) { c.checked = false }
      filterSubCategories(c);
    });

  };

  const filterSubCategories = (cat) => {
    subCategories.forEach((sC) => {
      if (cat.disabled && cat.id === sC.parent_id) {
        sC.disabled = true;
        sC.checked = false;
      } else {
        let gState = [];
        let aState = [];
        gender.forEach((g) => {
          if (g.checked) {
            if (sC.gender.includes(g.name)) {
              gState.push(true);
            } else {
              gState.push(false);
            }
          }
        })
        if ((ageRange[0] < sC.ageFrom && ageRange[1] < sC.ageFrom) || (ageRange[0] > sC.ageTo && ageRange[1] > sC.ageTo)) {
          aState.push(false);
        } else {
          aState.push(true);
        }

        sC.disabled = !gState.includes(true) || !aState.includes(true);
        if (sC.disabled) { sC.checked = false }
      }
    });
  };

  const handleClick = async (e) => {
    e.preventDefault();
    let genderIds = [];
    let relationsIds = [];
    let occasionsIds = [];
    let categoriesIds = [];
    let subCategoriesIds = [];
    let imgData = "";
    if (productImage) {
      if (productImage[0] === "d") {
        imgData = 'data:' + productImage.replace(/^data:image.+;base64,/, '');
      } else if (productImage[0] === "h") {
        imgData = undefined;
      } else {
        imgData = undefined;
      }
    } else {
      imgData = "";
    }

    gender.forEach((g) => {
      if (g.checked) {
        genderIds.push(g.key)
      }
    })
    relations.forEach((r) => {
      if (r.checked) {
        relationsIds.push(r.id);
      }
    })
    occasions.forEach((o) => {
      if (o.checked) {
        occasionsIds.push(o.id);
      }
    })
    categories.forEach((c) => {
      if (c.checked) {
        categoriesIds.push(c.id);
      }
    })
    subCategories.forEach((sC) => {
      if (sC.checked) {
        subCategoriesIds.push(sC.id);
      }
    })

    if (mainInputs.name === undefined || mainInputs.name === "") {
      enqueueSnackbar("Produkt musi mieć nazwę", { variant: 'error' });
    } else if (mainInputs.sku === undefined || mainInputs.sku === "") {
      enqueueSnackbar("Produkt musi mieć SKU", { variant: 'error' });
    } else if (mainInputs.ean === undefined || mainInputs.ean === "") {
      enqueueSnackbar("Produkt musi mieć EAN", { variant: 'error' });
    } else if (mainInputs.price === undefined || mainInputs.price === "") {
      enqueueSnackbar("Produkt musi mieć cenę", { variant: 'error' });
    } else if (mainInputs.stockQuantity === undefined || mainInputs.stockQuantity === "") {
      enqueueSnackbar("Produkt musi mieć ilość w magazynie", { variant: 'error' });
    } else if (genderIds.length === 0 && !excludeBox) {
      enqueueSnackbar("Musisz wybrać przynajmniej jedną płeć", { variant: 'error' });
    } else if (relationsIds.length === 0 && !excludeBox) {
      enqueueSnackbar("Musisz wybrać przynajmniej jedną relację", { variant: 'error' });
    } else if (occasionsIds.length === 0 && !excludeBox) {
      enqueueSnackbar("Musisz wybrać przynajmniej jedną okazję", { variant: 'error' });
    } else if (categoriesIds.length === 0 && subCategoriesIds.length === 0 && !excludeBox) {
      enqueueSnackbar("Musisz wybrać przynajmniej jedną kategorię", { variant: 'error' });
    } else {
      const herobox_params = {
        herobox_gender: genderIds.length > 0 && JSON.stringify(genderIds),
        herobox_relations: relationsIds.length > 0 && JSON.stringify(relationsIds),
        herobox_occasions: occasionsIds.length > 0 && JSON.stringify(occasionsIds),
        herobox_categories: categoriesIds.length > 0 && JSON.stringify(categoriesIds),
        herobox_subCategories: subCategoriesIds.length > 0 && JSON.stringify(subCategoriesIds),
        herobox_age: JSON.stringify(ageRange)
      }
      const product = {
        product_id: toEdit && productId,
        ean: mainInputs.ean,
        sku: mainInputs.sku,
        tax_rate: 23,
        prices: {
          "136": mainInputs.price
        },
        stock: {
          "bl_177": mainInputs.stockQuantity
        },
        text_fields: {
          name: mainInputs.name,
          extra_field_6871: JSON.stringify(herobox_params)
        },
        "images": {
          0: imgData
        }
      };
      if (excludeBox) { product.text_fields = { ...product.text_fields, extra_field_6873: "TAK" } }
      else { product.text_fields = { ...product.text_fields, extra_field_6873: "NIE" } }
      try {
        const res = await addInventoryProduct(product);
        if (res.data.status === "SUCCESS") {
          if (toEdit) {
            enqueueSnackbar("Pomyślnie zaktualizowano produkt ID: " + res.data.product_id, { variant: 'success' });
          } else {
            enqueueSnackbar("Pomyślnie dodano produkt ID: " + res.data.product_id, { variant: 'success' });
          }
          navigate('/products');
        } else {
          enqueueSnackbar(res.data.status + ": " + res.data.error_code + " - " + res.data.error_message, { variant: 'error' });
        }
      } catch (error) {
        enqueueSnackbar(error, { variant: 'error' });
      }
    }
  };

  return (
    <div className="container">
      <Sidebar />
      {ready &&
        <div className="productForm">
          <h1 className="productFormTitle">Produkt</h1>
          <span className="productId">{toEdit && "Id: " + productId}</span>
          <form className="productForm">
            {/* ZDJĘCIE */}
            <div className="productFormItem productImage">
              {productImage ?
                <div id="product-editable-image">
                  <div className={`product-image`} >
                    <div className='product-img-container' style={{ backgroundImage: `url(${productImage})` }}>
                      <span className="remove-image" onClick={() => setProductImage(false)}>
                        <RemoveCircleOutlineIcon />
                      </span>
                      <img src={productImage} alt="" />
                    </div>
                  </div>
                </div>
                : <ButtonUploadImage typeImage="thumbnail" onChange={(thumbnail) => handleThumbnailProduct(thumbnail.target.files[0])} />}
            </div>
            <div className="productFormItem">
              <FormControlLabel control={<Switch checked={excludeBox} onChange={handleToBox} inputProps={{ 'aria-label': 'excludeBox' }} />} label={"Czy produkt ma być wykluczony z losowania do boxów? - " + (excludeBox ? "TAK" : "NIE")} />
            </div>
            <div className="productFormItem">
              <TextField fullWidth id="name" label="Nazwa produktu" variant="outlined" defaultValue={mainInputs.name} onChange={handleMainInputs} />
            </div>
            <div className="productFormItem">
              <TextField fullWidth id="sku" label="SKU" variant="outlined" defaultValue={mainInputs.sku} onChange={handleMainInputs} />
            </div>
            <div className="productFormItem">
              <TextField fullWidth id="ean" label="EAN" variant="outlined" defaultValue={mainInputs.ean} onChange={handleMainInputs} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }} />
            </div>
            <div className="productFormItem">
              <TextField fullWidth type="number" id="price" label="Cena" defaultValue={mainInputs.price} variant="outlined" onChange={handleMainInputs} />
            </div>
            <div className="productFormItem">
              <TextField fullWidth id="stockQuantity" label="Ilośc w magazynie" variant="outlined" defaultValue={mainInputs.stockQuantity} onChange={handleMainInputs} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }} />
            </div>
            <div className="productFormItem">
              {/* WIEK */}
              <Accordion expanded={expanded === 'age'} onChange={handleAccordion('age')}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="age-content"
                  id="age-header"
                >
                  <Typography>Wiek</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography component={'span'}>
                    <p>Wiek od: {ageRange[0]} lat do: {ageRange[1]} lat (włącznie)</p>
                    <Box sx={{ width: 500 }}>
                      <Slider
                        getAriaLabel={() => 'Wiek'}
                        value={ageRange}
                        min={1}
                        max={100}
                        onChange={handleAge}
                        valueLabelDisplay="auto"
                        getAriaValueText={agetext}
                      />
                    </Box>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              {/* PŁCIE */}
              <Accordion expanded={expanded === 'gender'} onChange={handleAccordion('gender')}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="gender-content"
                  id="gender-header"
                >
                  <Typography>Płeć</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography component={'span'}>
                    <div className="checkbox-container">
                      {gender.map((g) => (
                        <div key={g.key}>
                          <FormControlLabel control={<Checkbox onChange={handleGender} checked={g.checked} inputProps={{ 'aria-label': g.key }} />} label={g.label} />
                        </div>
                      ))}
                    </div>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              {/* RELACJE */}
              <Accordion disabled={disabledRelations} expanded={expanded === 'relations'} onChange={handleAccordion('relations')}>

                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="relations-content"
                  id="relations-header"
                >
                  <Typography>Relacje</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography component={'span'}>
                    <FormControlLabel control={<Checkbox onChange={handleRelations} checked={allRelationsChecked} inputProps={{ 'aria-label': 'all-relations' }} />} label="Zaznacz/odznacz wszystkie dostępne" />
                    <div className="checkbox-container">
                      {relations.map((r) => (
                        <div key={r.id}>
                          <FormControlLabel control={<Checkbox onChange={handleRelations} disabled={r.disabled} checked={r.checked} inputProps={{ 'aria-label': r.id }} />} label={r.name} />
                        </div>
                      ))}
                    </div>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              {/* OKAZJE */}
              <Accordion disabled={disabledOccasions} expanded={expanded === 'occasions'} onChange={handleAccordion('occasions')}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="occasions-content"
                  id="occasions-header"
                >
                  <Typography>Okazje</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography component={'span'}>
                    <FormControlLabel control={<Checkbox onChange={handleOccasions} checked={allOccasionsChecked} inputProps={{ 'aria-label': 'all-occasions' }} />} label="Zaznacz/odznacz wszystkie dostępne" />
                    <div className="checkbox-container">
                      {occasions.map((o) => (
                        <div key={o.id}>
                          <FormControlLabel control={<Checkbox onChange={handleOccasions} disabled={o.disabled} checked={o.checked} inputProps={{ 'aria-label': o.id }} />} label={o.name} />
                        </div>
                      ))}
                    </div>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              {/* KATEGORIE I PODKATEGORIE */}
              <Accordion disabled={disabledCategories} expanded={expanded === 'categories'} onChange={handleAccordion('categories')}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="categories-content"
                  id="categories-header"
                >
                  <Typography>Kategorie i podkategorie</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography component={'span'}>
                    <div className="checkbox-container">
                      {categories.map((c) => (
                        <div key={c.id} className="categoryElem">
                          {c.subCats ?
                            <FormControl>
                              <InputLabel>{c.name}</InputLabel>
                              <Select
                                id={c.name}
                                name={c.name}
                                multiple
                                onChange={handleSubCategories}
                                value={subCategories.filter(sC => (sC.parent_id === c.id && sC.checked)).map((sc) => sc.name)}
                                input={<OutlinedInput label={c.name} />}
                                renderValue={(selected) => selected.join(', ')}
                                sx={{ width: 599 }}
                              >
                                {subCategories.map((sC) => (
                                  sC.parent_id === c.id &&
                                  <MenuItem key={sC.id} value={sC.name} disabled={sC.disabled}>
                                    <Checkbox checked={sC.checked} />
                                    <ListItemText primary={sC.name} />
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            :
                            <FormControlLabel control={<Checkbox onChange={handleCategories} disabled={c.disabled} checked={c.checked} inputProps={{ 'aria-label': c.id }} />} label={c.name} />

                          }
                        </div>
                      ))}
                    </div>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
            <button onClick={handleClick} className="productFormButton" >{!toEdit ? "Dodaj nowy" : "Auktualizuj"} produkt</button>
          </form>
        </div>
      }
    </div>
  );
}
