const Categories = {
    category: [
        {
            id: 1,
            name: "Dekoracyjny",
            ageFrom: 15,
            ageTo: 100,
            occasions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
            gender: ["male", "female"],
            dayFrom: 1,
            monthFrom: 1,
            dayTo: 31,
            monthTo: 12,
        },
        {
            id: 2,
            name: "Dla zdrowia",
            ageFrom: 15,
            ageTo: 100,
            occasions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
            gender: ["male", "female"],
            dayFrom: 1,
            monthFrom: 1,
            dayTo: 31,
            monthTo: 12,
        },
        {
            id: 3,
            name: "Dla urody",
            ageFrom: 14,
            ageTo: 100,
            occasions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
            gender: ["female"],
            dayFrom: 1,
            monthFrom: 1,
            dayTo: 31,
            monthTo: 12,
        },
        {
            id: 4,
            name: "Do poczytania",
            ageFrom: 3,
            ageTo: 10,
            occasions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
            gender: ["male", "female"],
            dayFrom: 1,
            monthFrom: 1,
            dayTo: 31,
            monthTo: 12,
        },
        {
            id: 5,
            name: "Edukacyjny",
            ageFrom: 3,
            ageTo: 12,
            occasions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
            gender: ["male", "female"],
            dayFrom: 1,
            monthFrom: 1,
            dayTo: 31,
            monthTo: 12,
        },
        {
            id: 6,
            name: "Elegancki",
            ageFrom: 17,
            ageTo: 100,
            occasions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
            gender: ["male", "female"],
            dayFrom: 1,
            monthFrom: 1,
            dayTo: 31,
            monthTo: 12,
        },
        {
            id: 7,
            name: "Kreatywny",
            ageFrom: 3,
            ageTo: 100,
            occasions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
            gender: ["male", "female"],
            dayFrom: 1,
            monthFrom: 1,
            dayTo: 31,
            monthTo: 12,
        },
        {
            id: 8,
            name: "Na sportowo",
            ageFrom: 5,
            ageTo: 100,
            occasions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
            gender: ["male", "female"],
            dayFrom: 1,
            monthFrom: 1,
            dayTo: 31,
            monthTo: 12,
        },
        {
            id: 9,
            name: "Użyteczny",
            ageFrom: 11,
            ageTo: 100,
            occasions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
            gender: ["male", "female"],
            dayFrom: 1,
            monthFrom: 1,
            dayTo: 31,
            monthTo: 12,
        },
        {
            id: 10,
            name: "Ręcznie robiony",
            ageFrom: 15,
            ageTo: 100,
            occasions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
            gender: ["female"],
            dayFrom: 1,
            monthFrom: 1,
            dayTo: 31,
            monthTo: 12,
        },
        {
            id: 11,
            name: "Ręcznie robiony",
            ageFrom: 20,
            ageTo: 100,
            occasions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
            gender: ["male"],
            dayFrom: 1,
            monthFrom: 1,
            dayTo: 31,
            monthTo: 12,
        },
        {
            id: 12,
            name: "Rozrywkowy",
            ageFrom: 3,
            ageTo: 100,
            occasions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
            gender: ["male", "female"],
            dayFrom: 1,
            monthFrom: 1,
            dayTo: 31,
            monthTo: 12,
        },
        {
            id: 13,
            name: "Sprośny",
            ageFrom: 18,
            ageTo: 35,
            occasions: [6, 7, 8],
            gender: ["male", "female"],
            dayFrom: 1,
            monthFrom: 1,
            dayTo: 31,
            monthTo: 12,
        },
        {
            id: 14,
            name: "Na topie",
            ageFrom: 4,
            ageTo: 16,
            occasions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
            gender: ["male", "female"],
            dayFrom: 1,
            monthFrom: 1,
            dayTo: 31,
            monthTo: 12,
        },
        {
            id: 15,
            name: "Wypełniacz",
            ageFrom: 1,
            ageTo: 100,
            occasions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
            gender: ["male", "female"],
            dayFrom: 1,
            monthFrom: 1,
            dayTo: 31,
            monthTo: 12,
        },
        {
            id: 16,
            name: "Świąteczne gadżety",
            ageFrom: 1,
            ageTo: 100,
            occasions: [11, 12],
            gender: ["male", "female"],
            dayFrom: 10,
            monthFrom: 10,
            dayTo: 26,
            monthTo: 12,

        },
        {
            id: 17,
            name: "Halloweenowe gadżety",
            ageFrom: 1,
            ageTo: 100,
            occasions: [14],
            gender: ["male", "female"],
            dayFrom: 15,
            monthFrom: 9,
            dayTo: 31,
            monthTo: 10,

        },
        {
            id: 18,
            name: "Wielkanocne gadżety",
            ageFrom: 1,
            ageTo: 100,
            occasions: [15],
            gender: ["male", "female"],
            dayFrom: 1,
            monthFrom: 3,
            dayTo: 25,
            monthTo: 4,

        },
        {
            id: 19,
            name: "Dla zakochanych",
            ageFrom: 1,
            ageTo: 100,
            occasions: [23],
            gender: ["male", "female"],
            dayFrom: 10,
            monthFrom: 1,
            dayTo: 14,
            monthTo: 2,
        },
        {
            id: 20,
            name: "Mól książkowy",
            ageFrom: 14,
            ageTo: 100,
            occasions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
            gender: ["male", "female"],
            dayFrom: 1,
            monthFrom: 1,
            dayTo: 31,
            monthTo: 12,
        },
        {
            id: 21,
            name: "Relaks",
            ageFrom: 14,
            ageTo: 100,
            occasions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
            gender: ["male", "female"],
            dayFrom: 1,
            monthFrom: 1,
            dayTo: 31,
            monthTo: 12,
        }
    ]
};

export default Categories;