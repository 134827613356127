const Relations = {
    relation: [
        {
            id: 1,
            name: "Mama",
            ageFrom: 18,
            ageTo: 100,
            toWho: ["family"],
            gender: ["female"]
        },
        {
            id: 2,
            name: "Tata",
            ageFrom: 18,
            ageTo: 100,
            toWho: ["family"],
            gender: ["male"]
        },
        {
            id: 3,
            name: "Rodzice",
            ageFrom: 18,
            ageTo: 100,
            toWho: ["family"],
            gender: ["male", "female"]
        },
        {
            id: 4,
            name: "Siostra",
            ageFrom: 1,
            ageTo: 100,
            toWho: ["family"],
            gender: ["female"]
        },
        {
            id: 5,
            name: "Brat",
            ageFrom: 1,
            ageTo: 100,
            toWho: ["family"],
            gender: ["male"]
        },
        {
            id: 6,
            name: "Dziadek",
            ageFrom: 40,
            ageTo: 100,
            toWho: ["family"],
            gender: ["male"]
        },
        {
            id: 7,
            name: "Babcia",
            ageFrom: 40,
            ageTo: 100,
            toWho: ["family"],
            gender: ["female"]
        },
        {
            id: 8,
            name: "Syn",
            ageFrom: 1,
            ageTo: 55,
            toWho: ["family"],
            gender: ["male"]
        },
        {
            id: 9,
            name: "Córka",
            ageFrom: 1,
            ageTo: 55,
            toWho: ["family"],
            gender: ["female"]
        },
        {
            id: 10,
            name: "Chrześniak",
            ageFrom: 1,
            ageTo: 18,
            toWho: ["family"],
            gender: ["male"]
        },
        {
            id: 11,
            name: "Chrześniaczka",
            ageFrom: 1,
            ageTo: 18,
            toWho: ["family"],
            gender: ["female"]
        },
        {
            id: 12,
            name: "Teść",
            ageFrom: 35,
            ageTo: 100,
            toWho: ["family"],
            gender: ["male"]
        },
        {
            id: 13,
            name: "Teściowa",
            ageFrom: 35,
            ageTo: 100,
            toWho: ["family"],
            gender: ["female"]
        },
        {
            id: 14,
            name: "Wnuczek",
            ageFrom: 1,
            ageTo: 25,
            toWho: ["family"],
            gender: ["male"]
        },
        {
            id: 15,
            name: "Wnuczka",
            ageFrom: 1,
            ageTo: 25,
            toWho: ["family"],
            gender: ["female"]
        },
        {
            id: 16,
            name: "Inny krewny",
            ageFrom: 1,
            ageTo: 100,
            toWho: ["family"],
            gender: ["male"]
        },
        {
            id: 17,
            name: "Inna krewna",
            ageFrom: 1,
            ageTo: 100,
            toWho: ["family"],
            gender: ["female"]
        },
        {
            id: 18,
            name: "Mąż",
            ageFrom: 18,
            ageTo: 100,
            toWho: ["family"],
            gender: ["male"]
        },
        {
            id: 19,
            name: "Żona",
            ageFrom: 18,
            ageTo: 100,
            toWho: ["family"],
            gender: ["female"]
        },
        {
            id: 20,
            name: "Partner",
            ageFrom: 25,
            ageTo: 100,
            toWho: ["family", "outFamily"],
            gender: ["male"]
        },
        {
            id: 21,
            name: "Partnerka",
            ageFrom: 25,
            ageTo: 100,
            toWho: ["family", "outFamily"],
            gender: ["female"]
        },
        {
            id: 22,
            name: "Chłopak",
            ageFrom: 12,
            ageTo: 24,
            toWho: ["outFamily"],
            gender: ["male"]
        },
        {
            id: 23,
            name: "Dziewczyna",
            ageFrom: 12,
            ageTo: 24,
            toWho: ["outFamily"],
            gender: ["female"]
        },
        {
            id: 24,
            name: "Przyjaciel",
            ageFrom: 6,
            ageTo: 100,
            toWho: ["outFamily"],
            gender: ["male"]
        },
        {
            id: 25,
            name: "Przyjaciółka",
            ageFrom: 6,
            ageTo: 100,
            toWho: ["outFamily"],
            gender: ["female"]
        },
        {
            id: 26,
            name: "Znajomy",
            ageFrom: 17,
            ageTo: 100,
            toWho: ["outFamily"],
            gender: ["male"]
        },
        {
            id: 27,
            name: "Znajoma",
            ageFrom: 17,
            ageTo: 100,
            toWho: ["outFamily"],
            gender: ["female"]
        },
        {
            id: 28,
            name: "Kolega",
            ageFrom: 6,
            ageTo: 16,
            toWho: ["outFamily"],
            gender: ["male"]
        },
        {
            id: 29,
            name: "Koleżanka",
            ageFrom: 6,
            ageTo: 16,
            toWho: ["outFamily"],
            gender: ["female"]
        },
        {
            id: 30,
            name: "Nauczyciel",
            ageFrom: 18,
            ageTo: 100,
            toWho: ["outFamily"],
            gender: ["male"]
        },
        {
            id: 31,
            name: "Nauczycielka",
            ageFrom: 18,
            ageTo: 100,
            toWho: ["outFamily"],
            gender: ["female"]
        },
        {
            id: 32,
            name: "Przełożony",
            ageFrom: 18,
            ageTo: 100,
            toWho: ["outFamily"],
            gender: ["male"]
        },
        {
            id: 33,
            name: "Przełożona",
            ageFrom: 18,
            ageTo: 100,
            toWho: ["outFamily"],
            gender: ["female"]
        },
        {
            id: 34,
            name: "Pracownik",
            ageFrom: 18,
            ageTo: 100,
            toWho: ["outFamily"],
            gender: ["male"]
        },
        {
            id: 35,
            name: "Pracownica",
            ageFrom: 18,
            ageTo: 100,
            toWho: ["outFamily"],
            gender: ["female"]
        },
        {
            id: 36,
            name: "Inna osoba",
            ageFrom: 1,
            ageTo: 100,
            toWho: ["outFamily"],
            gender: ["male", "female"]
        }
    ]
};


export default Relations;