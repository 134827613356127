import { useState } from "react";
import { Button, TextField } from "@mui/material";
import "./boxDecoder2.css";
import Relations from "../../box_info/Realtions";
import Occasions from "../../box_info/Occasions";
import Categories from "../../box_info/Categories";
import SubCategories from "../../box_info/SubCategories";
import Sidebar from "../../components/sidebar/Sidebar";


export default function BoxDecoder2() {
  const [mainInput, setMainInputs] = useState("");
  const [box, setBox] = useState({});

  const handleMainInputs = (e) => {
    setMainInputs(e.target.value);
  };

  const handleClick = () => {
    try {
      if (mainInput !== "" && mainInput) {
        const req_box_params = mainInput.split('box_params: ');
        const box_params = {
          ...JSON.parse(JSON.parse('"' + req_box_params[1] + '"'))
        };
        setBox(box_params);
      }
    } catch (err) {
      console.log(err)
      setBox({})
    }

  };

  return (
    <div className="container">
      <Sidebar />

      <div className="decoderForm">
        <div className="productFormItem">
          <TextField fullWidth id="box_params" label="Dane boxa" variant="outlined" multiline={true} minRows={5} onChange={handleMainInputs} />
        </div>
        <Button variant="contained" onClick={handleClick}>Dekoduj dane</Button>
        <>
          {Object.keys(box).length !== 0 &&
            <div className="decodedInfo">
              Płeć: {box.g === 1 ? "Mężczyzna" : "Kobieta"}<br />
              Wiek: {box.a}<br />
              {box.r &&
                <>
                  Relacja: {Relations.relation.filter(r => r.id === box.r)[0].name}<br />
                  Okazja: {Occasions.occasion.filter(o => o.id === box.o)[0].name}<br />
                </>
              }
              Kategorie: <ul>{box.c?.map((c) => (<li key={c}>{Categories.category.filter(cf => cf.id === c)[0].name}</li>))}</ul>
              {box.sC && <>Podkategorie: <ul>{box.sC?.map((sC) => (<li key={sC}>{<>{SubCategories.subCategory.filter(sCf => sCf.id === sC)[0].name}</>}</li>))}</ul></>}
              Słodycze: {box.f === 1 && <strong>Tak</strong>}{box.f === 0 && "Nie"}<br />
              Pakowanie w karton: {box.p === 1 && <strong>Tak (20zł)</strong>}{box.p === 0 && "Nie"}<br />
              {box.r &&
                <>Kartka: {box.l === 1 ? <strong>Tak</strong> : "Nie"}<br /></>}

            </div>
          }
        </>
      </div>
    </div>
  );

};