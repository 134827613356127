const SubCategories = {
    subCategory: [
        {
            parent_id: 4,
            id: 1,
            name: "Kolorwanki",
            ageFrom: 3,
            ageTo: 8,
            gender: ["male", "female"]
        },
        {
            parent_id: 4,
            id: 2,
            name: "Klasyczne opowieści",
            ageFrom: 8,
            ageTo: 13,
            gender: ["male", "female"]
        },
        {
            parent_id: 4,
            id: 3,
            name: "Nawiązujące do bajek",
            ageFrom: 3,
            ageTo: 13,
            gender: ["male", "female"]
        },
        {
            parent_id: 4,
            id: 4,
            name: "Komiksy",
            ageFrom: 3,
            ageTo: 13,
            gender: ["male", "female"]
        },
        {
            parent_id: 4,
            id: 5,
            name: "Fantastyka",
            ageFrom: 8,
            ageTo: 13,
            gender: ["male", "female"]
        },
        {
            parent_id: 4,
            id: 6,
            name: "Popularnonaukowe",
            ageFrom: 8,
            ageTo: 13,
            gender: ["male", "female"]
        },
        {
            parent_id: 5,
            id: 9,
            name: "Nauka angielskiego",
            ageFrom: 5,
            ageTo: 10,
            gender: ["male", "female"]
        },
        {
            parent_id: 5,
            id: 10,
            name: "Zabawki i gry edukacyjne",
            ageFrom: 3,
            ageTo: 13,
            gender: ["male", "female"]
        },
        {
            parent_id: 5,
            id: 11,
            name: "Łamigłówki",
            ageFrom: 12,
            ageTo: 100,
            gender: ["male", "female"]
        },
        {
            parent_id: 5,
            id: 13,
            name: "Drobne instrumenty",
            ageFrom: 4,
            ageTo: 6,
            gender: ["male", "female"]
        },
        {
            parent_id: 5,
            id: 16,
            name: "Mały naukowiec",
            ageFrom: 3,
            ageTo: 12,
            gender: ["male", "female"]
        },
        {
            parent_id: 7,
            id: 17,
            name: "Majsterkowicz",
            ageFrom: 3,
            ageTo: 100,
            gender: ["male"]
        },
        {
            parent_id: 7,
            id: 18,
            name: "Rękodzieło",
            ageFrom: 3,
            ageTo: 100,
            gender: ["male"]
        },
        {
            parent_id: 7,
            id: 19,
            name: "Rysunek",
            ageFrom: 3,
            ageTo: 100,
            gender: ["male", "female"]
        },
        {
            parent_id: 7,
            id: 23,
            name: "Układanki",
            ageFrom: 3,
            ageTo: 100,
            gender: ["male", "female"]
        },
        {
            parent_id: 7,
            id: 24,
            name: "Klocki",
            ageFrom: 3,
            ageTo: 100,
            gender: ["male", "female"]
        },
        {
            parent_id: 7,
            id: 25,
            name: "Biżuteria",
            ageFrom: 3,
            ageTo: 9,
            gender: ["female"]
        },
        {
            parent_id: 8,
            id: 26,
            name: "Fitness",
            ageFrom: 5,
            ageTo: 100,
            gender: ["male", "female"]
        },
        {
            parent_id: 8,
            id: 27,
            name: "Rower",
            ageFrom: 5,
            ageTo: 100,
            gender: ["male", "female"]
        },
        {
            parent_id: 8,
            id: 29,
            name: "Trekking",
            ageFrom: 5,
            ageTo: 100,
            gender: ["male", "female"]
        },
        {
            parent_id: 8,
            id: 30,
            name: "Uniwersalne akcesoria",
            ageFrom: 5,
            ageTo: 100,
            gender: ["male", "female"]
        },
        {
            parent_id: 8,
            id: 33,
            name: "Gry i zabawy sportowe",
            ageFrom: 5,
            ageTo: 100,
            gender: ["male", "female"]
        },
        {
            parent_id: 9,
            id: 34,
            name: "Samochód",
            ageFrom: 18,
            ageTo: 100,
            gender: ["male", "female"]
        },
        {
            parent_id: 9,
            id: 35,
            name: "Mierzenie czasu",
            ageFrom: 11,
            ageTo: 100,
            gender: ["male", "female"]
        },
        {
            parent_id: 9,
            id: 36,
            name: "Akcesoria kuchenne",
            ageFrom: 11,
            ageTo: 100,
            gender: ["male", "female"]
        },
        {
            parent_id: 9,
            id: 38,
            name: "Akcesoria podróżne",
            ageFrom: 11,
            ageTo: 100,
            gender: ["male", "female"]
        },
        {
            parent_id: 9,
            id: 41,
            name: "Akcesoria szkolne",
            ageFrom: 11,
            ageTo: 20,
            gender: ["male", "female"]
        },
        {
            parent_id: 9,
            id: 43,
            name: "Gadżety użyteczne",
            ageFrom: 11,
            ageTo: 100,
            gender: ["male", "female"]
        },
        {
            parent_id: 9,
            id: 44,
            name: "Oświetlenie",
            ageFrom: 11,
            ageTo: 100,
            gender: ["male", "female"]
        },
        {
            parent_id: 9,
            id: 45,
            name: "Biuro",
            ageFrom: 18,
            ageTo: 100,
            gender: ["male", "female"]
        },
        {
            parent_id: 12,
            id: 46,
            name: "Gry imprezowe",
            ageFrom: 3,
            ageTo: 100,
            gender: ["male", "female"]
        },
        {
            parent_id: 12,
            id: 47,
            name: "Gry rodzinne",
            ageFrom: 3,
            ageTo: 100,
            gender: ["male", "female"]
        },
        {
            parent_id: 12,
            id: 48,
            name: "Zabawki",
            ageFrom: 3,
            ageTo: 12,
            gender: ["male", "female"]
        },
        {
            parent_id: 12,
            id: 49,
            name: "Gadżety rozrywkowe",
            ageFrom: 12,
            ageTo: 100,
            gender: ["male", "female"]
        }
    ]
};

export default SubCategories;