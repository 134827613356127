import "./sidebar.css";
import AddIcon from '@mui/icons-material/Add';
import StorefrontIcon from '@mui/icons-material/Storefront';
import InventoryIcon from '@mui/icons-material/Inventory';
import LineStyleIcon from '@mui/icons-material/LineStyle';
import SocialDistanceIcon from '@mui/icons-material/SocialDistance';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import LogoutIcon from '@mui/icons-material/Logout';
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../redux/apiCalls";



export default function Sidebar() {
  const dispatch = useDispatch();
  const lo = (e) => {
    e.preventDefault();
    logoutUser(dispatch);
  };
  return (
    <div className="sidebar">
      <div className="sidebarWrapper">
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Dashboard</h3>
          <ul className="sidebarList">
            <Link to="/" className="link">
              <li className="sidebarListItem">
                <InventoryIcon className="sidebarIcon" />
                Home
              </li>
            </Link>
            <Link to="/products" className="link">
              <li className="sidebarListItem">
                <StorefrontIcon className="sidebarIcon" />
                Produkty
              </li>
            </Link>
            <Link to="/relations" className="link">
              <li className="sidebarListItem">
                <SocialDistanceIcon className="sidebarIcon" />
                Relacje
              </li>
            </Link>
            <Link to="/occasions" className="link">
              <li className="sidebarListItem">
                <CalendarMonthIcon className="sidebarIcon" />
                Okazje
              </li>
            </Link>
            <Link to="/categories" className="link">
              <li className="sidebarListItem">
                <LineStyleIcon className="sidebarIcon" />
                Kategorie
              </li>
            </Link>
            <Link to="/subcategories" className="link">
              <li className="sidebarListItem">
                <LineStyleIcon className="sidebarIcon" />
                Podkategorie
              </li>
            </Link>
            <Link reloadDocument to="/product" className="link">
              <li className="sidebarListItem">
                <AddIcon className="sidebarIcon" />
                Dodaj nowy produkt
              </li>
            </Link>
            <Link to="/decoder" className="link">
              <li className="sidebarListItem">
                <SettingsSuggestIcon className="sidebarIcon" />
                Dekoder boxów
              </li>
            </Link>
            <Link to="/decoder2" className="link">
              <li className="sidebarListItem">
                <SettingsSuggestIcon className="sidebarIcon" />
                Dekoder boxów 2
              </li>
            </Link>
            <Link to="/login" className="link bottomLink" onClick={lo}>
              <li className="sidebarListItem">
                <LogoutIcon className="sidebarIcon" />
                Wyloguj
              </li>
            </Link>

          </ul>
        </div>
      </div>
    </div>
  );
}
