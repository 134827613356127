import { useCallback, useEffect, useState } from "react";
import { DataGrid } from '@mui/x-data-grid';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Link } from "react-router-dom";
import { getInventoryProductsList, getInventoryProductsData, deleteInventoryProduct } from "../../redux/apiCalls";
import Sidebar from "../../components/sidebar/Sidebar";
import "./productList.css";
import { useSnackbar } from 'notistack';

const DEFAULT_PER_PAGE = 20;

export default function ProductList() {
  const { enqueueSnackbar } = useSnackbar();

  const [productsCount, setProductsCount] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(DEFAULT_PER_PAGE);
  const [products, setProducts] = useState([]);
  const [productsList, setProductsList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [sortQueryOptions, setSortQueryOptions] = useState({});
  const [filterQueryOptions, setFilterQueryOptions] = useState({});

  useEffect(() => {
    getProductsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterQueryOptions]);

  useEffect(() => {
    if (sortQueryOptions['sortModel'] && sortQueryOptions['sortModel'].length > 0) {
      if (sortQueryOptions['sortModel'][0].sort === "desc") {
        let sortedList = [];
        sortedList = productsList.sort((a, b) => sortQueryOptions['sortModel'][0].field === "name" ? b.name.localeCompare(a.name) : b[sortQueryOptions['sortModel'][0].field] - a[sortQueryOptions['sortModel'][0].field]);
        setProductsList(sortedList);
      } else {
        let sortedList = [];
        sortedList = productsList.sort((b, a) => sortQueryOptions['sortModel'][0].field === "name" ? b.name.localeCompare(a.name) : b[sortQueryOptions['sortModel'][0].field] - a[sortQueryOptions['sortModel'][0].field]);
        setProductsList(sortedList);
      }
    };
    let tmpProductsIds = [];
    tmpProductsIds = [...productsList.map(product => {
      return product.id
    })];
    const productsIdsData = tmpProductsIds.slice(page * pageSize, (page * pageSize) + pageSize);
    getProductsData(productsIdsData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize, productsList, sortQueryOptions]);

  const getProductsList = async () => {
    setLoading(true);
    let fetching = true;
    let ready = false;
    let pageFetch = 1;
    let tmpProductsList = [];
    while (fetching) {
      try {
        const res = await getInventoryProductsList(pageFetch);
        if (res.data.status === "SUCCESS") {
          if (Object.keys(res.data.products).length > 0) {
            tmpProductsList = [...tmpProductsList, ...(Object.keys(res.data.products).map(id => {
              return {
                id: res.data.products[id]['id'],
                name: res.data.products[id]['name'],
                sku: res.data.products[id]['sku'],
                ean: res.data.products[id]['ean'],
                price: res.data.products[id]['prices']['136'],
                stock: res.data.products[id]['stock']['bl_177']
              };
            }))];
            pageFetch = pageFetch + 1;
          } else {
            ready = true;
            fetching = false;
            setProductsCount(tmpProductsList.length);
          }
        } else {
          enqueueSnackbar(res.data.status + ": " + res.data.error_code + " - " + res.data.error_message, { variant: 'error' });
          fetching = false;
        }
      } catch (error) {
        enqueueSnackbar(error, { variant: 'error' });
        fetching = false;
      }
    }
    if (ready) {
      setProductsList(tmpProductsList);
      let tmpProductsIds = [];
      tmpProductsIds = [...tmpProductsList.map(product => {
        return product.id
      })];
      const productsIdsData = tmpProductsIds.slice(page * pageSize, (page * pageSize) + pageSize);
      getProductsData(productsIdsData);
    }
  }

  const getProductsData = async (tmpProductsIds) => {
    setLoading(true);
    if (tmpProductsIds.length > 0) {
      try {
        const res = await getInventoryProductsData(tmpProductsIds);
        if (res.data.status === "SUCCESS") {
          let products = [];
          for (const [key, value] of Object.entries(res.data.products)) {
            products.push({ id: key, ...value })
          }
          if (sortQueryOptions['sortModel'] && sortQueryOptions['sortModel'].length > 0) {
            if (sortQueryOptions['sortModel'][0].sort === "desc") {
              products.reverse();
            }
          }
          setProducts(products);
          setLoading(false);
        } else {
          enqueueSnackbar(res.data.status + ": " + res.data.error_code + " - " + res.data.error_message, { variant: 'error' });
        }
      } catch (error) {
        enqueueSnackbar(error, { variant: 'error' });
      }
    }
  }

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      const res = await deleteInventoryProduct(id);
      if (res.data.status === "SUCCESS") {
        enqueueSnackbar("Pomyślnie usunięto produkt ID: " + id, { variant: 'success' });
        getProductsList();
        setLoading(false);
      } else {
        enqueueSnackbar(res.data.status + ": " + res.data.error_code + " - " + res.data.error_message, { variant: 'error' });
        setLoading(false);
      }
    } catch (error) {
      enqueueSnackbar(error, { variant: 'error' });
    }
    setLoading(false);
  }

  const handleSortModelChange = useCallback((sortModel) => {
    setSortQueryOptions({ sortModel: [...sortModel] });
  }, []);

  const onFilterChange = useCallback((filterModel) => {
    setFilterQueryOptions({ filterModel: { ...filterModel } });
  }, []);


  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.2,
      sort: "DESC",
    },
    {
      field: "exclude_box",
      headerName: "WYKLUCZONY??",
      flex: 0.15,
      sort: "DESC",
      sortable: false,
      renderCell: (params) => {
        return (
          <div>
            {params.row.text_fields["extra_field_6873"] !== undefined ? params.row.text_fields["extra_field_6873"] : "BRAK"}
          </div>
        );
      }
    },
    {
      field: "sku",
      headerName: "SKU",
      flex: 0.3,
    },
    {
      field: "name",
      headerName: "Produkt",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className="productListItem">
            <img className="productListImg" src={Object.keys(params.row.images).length > 0 ? params.row.images[1] : "https://thumbs.cdn.baselinker.com/thumb/shop/3014465/54371/0/60/45.png"} alt="" />
            {params.row.text_fields.name}
          </div>
        );
      },
    },
    {
      field: "price",
      headerName: "Cena",
      flex: 0.1,
      align: "right",
      renderCell: (params) => {
        return (
          <div>
            {params.row.prices['136']}

          </div>
        );
      },
    },
    {
      field: "stock",
      headerName: "Ilość",
      flex: 0.1,
      renderCell: (params) => {
        return (
          <div>
            {params.row.stock['bl_177']}
          </div>
        );
      },
    },
    {
      field: "gender",
      headerName: "Płeć",
      flex: 0.15,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <div>
            {(params.row.text_fields['extra_field_6871'] !== undefined && JSON.parse(params.row.text_fields['extra_field_6871'])['herobox_gender'] !== undefined && JSON.parse(params.row.text_fields['extra_field_6871'])['herobox_gender'] !== "" && JSON.parse(params.row.text_fields['extra_field_6871'])['herobox_gender'] !== false) ? "OK" : "BRAK"}
          </div>
        );
      },
    },
    {
      field: "relations",
      headerName: "Relacje",
      flex: 0.15,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <div>
            {(params.row.text_fields['extra_field_6871'] !== undefined && JSON.parse(params.row.text_fields['extra_field_6871'])['herobox_relations'] !== undefined && JSON.parse(params.row.text_fields['extra_field_6871'])['herobox_relations'] !== "" && JSON.parse(params.row.text_fields['extra_field_6871'])['herobox_relations'] !== false) ? "OK" : "BRAK"}
          </div>
        );
      },
    },
    {
      field: "occasions",
      headerName: "Okazje",
      flex: 0.15,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <div>
            {(params.row.text_fields['extra_field_6871'] !== undefined && JSON.parse(params.row.text_fields['extra_field_6871'])['herobox_occasions'] !== undefined && JSON.parse(params.row.text_fields['extra_field_6871'])['herobox_occasions'] !== "" && JSON.parse(params.row.text_fields['extra_field_6871'])['herobox_occasions'] !== false) ? "OK" : "BRAK"}
          </div>
        );
      },
    },
    {
      field: "categories",
      headerName: "Kategorie",
      flex: 0.15,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <div>
            {((params.row.text_fields['extra_field_6871'] !== undefined &&
              JSON.parse(params.row.text_fields['extra_field_6871'])['herobox_categories'] !== undefined &&
              JSON.parse(params.row.text_fields['extra_field_6871'])['herobox_categories'] !== "" &&
              JSON.parse(params.row.text_fields['extra_field_6871'])['herobox_categories'] !== false
            ) || (
                params.row.text_fields['extra_field_6871'] !== undefined &&
                JSON.parse(params.row.text_fields['extra_field_6871'])['herobox_subCategories'] !== undefined &&
                JSON.parse(params.row.text_fields['extra_field_6871'])['herobox_subCategories'] !== "" &&
                JSON.parse(params.row.text_fields['extra_field_6871'])['herobox_subCategories'] !== false
              )) ? "OK" : "BRAK"}
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Akcje",
      flex: 0.3,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <>
            <Link to={"/product/" + params.row.id}>
              <button className="productListEdit">Edytuj</button>
            </Link>
            <DeleteOutlineIcon
              className="productListDelete"
              onClick={() => handleDelete(params.row.id)}
            />
          </>
        );
      }
    }
  ];

  return (
    <div className="container">
      <Sidebar />
      <div className="productList">
        <div className="productAddContainer">
          <Link to="/product">
            <button className="productAddButton">Dodaj nowy produkt</button>
          </Link>
        </div>
        <div className="productListContainer">
          <DataGrid
            rows={products}
            disableSelectionOnClick
            disableColumnSelector
            autoHeight
            disableDensitySelector
            columns={columns}
            getRowId={row => row.id}
            getRowHeight={() => 'auto'}
            rowCount={productsCount}
            pageSize={pageSize}
            paginationMode="server"
            sortingMode="server"
            filterMode="server"
            disableColumnFilter
            onFilterModelChange={onFilterChange}
            onSortModelChange={handleSortModelChange}
            rowsPerPageOptions={[10, 20, 50, 100]}
            onPageSizeChange={(newSize) => setPageSize(newSize)}
            onPageChange={(newPage) => setPage(newPage)}
            loading={isLoading}
          />
        </div>
      </div>
    </div>
  );
}
