import { loginFailure, loginStart, loginSuccess, logout } from "./userRedux";
import { publicRequest, userRequest } from "../requestMethods";
const HEROBOX_INVENTORY = 132;

export const login = async (dispatch, user) => {
  dispatch(loginStart());
  try {
    const res = await publicRequest.post("/auth/login", user);
    dispatch(loginSuccess(res.data));
    window.location.reload();
  } catch (err) {
    dispatch(loginFailure());
  }
};

export const logoutUser = async (dispatch) => {
  dispatch(logout());
};

export const getInventoryProductsList = async (page = 1) => {
  const data = { "inventory_id": HEROBOX_INVENTORY, "page": page, "filter_sort": "id DESC" }
  const params = {
    "method": "getInventoryProductsList",
    "parameters": JSON.stringify(data),
  }
  return await userRequest.post('/baselinker', params)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    })
};

export const getInventoryProductsData = async (products) => {
  const data = { "inventory_id": HEROBOX_INVENTORY, "products": products }
  const params = {
    "method": "getInventoryProductsData",
    "parameters": JSON.stringify(data),
  }
  return await userRequest.post('/baselinker', params)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    })
};

export const addInventoryProduct = async (product) => {
  const data = { "inventory_id": HEROBOX_INVENTORY, ...product }
  const params = {
    "method": "addInventoryProduct",
    "parameters": JSON.stringify(data),
  }
  return await userRequest.post('/baselinker', params)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    })
};

export const deleteInventoryProduct = async (productID) => {
  const data = { "product_id": productID }
  const params = {
    "method": "deleteInventoryProduct",
    "parameters": JSON.stringify(data),
  }
  return await userRequest.post('/baselinker', params)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    })
};


export const getOrder = async (orderId) => {
  let data = { "order_id": orderId }
  const params = {
    "method": "getOrders",
    "parameters": JSON.stringify(data),
  }
  return await userRequest.post('/baselinker', params)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    })
};