import { useState } from "react";
import { Button, TextField } from "@mui/material";
import "./boxDecoder.css";
import Relations from "../../box_info/Realtions";
import Occasions from "../../box_info/Occasions";
import Categories from "../../box_info/Categories";
import SubCategories from "../../box_info/SubCategories";

import Sidebar from "../../components/sidebar/Sidebar";
import { getOrder } from "../../redux/apiCalls";

export default function BoxDecoder() {
  const [mainInput, setMainInputs] = useState("");
  const [box, setBox] = useState([]);

  const handleMainInputs = (e) => {
    setMainInputs(e.target.value);
  };

  const handleClick = async () => {
    if (mainInput !== "" && mainInput) {
      let boxes = [];
      const orderResponse = await getOrder(mainInput);
      orderResponse.data.orders.forEach((order) => {
        if (order['order_id'] === parseInt(mainInput)) {
          order['products'].forEach((product) => {
            if (product['sku'] === "MRBOX" || product['sku'] === "AGENTBOX" || product['sku'] === "HEROBOX") {
              const req_box_params = product['attributes'].split('box_params: ');
              const box_params = {
                order_product_id: product['order_product_id'],
                name: product['name'],
                price: product['price_brutto'],
                ...JSON.parse(JSON.parse('"' + req_box_params[1] + '"'))
              };
              boxes.push(box_params);
            }
          })
        }
      })
      setBox(boxes);
    }
  };

  return (
    <div className="container">
      <Sidebar />
      <div className="decoderForm">
        <div className="productFormItem">
          <TextField fullWidth id="box_params" label="Numer zamówienia" variant="outlined" multiline={true} minRows={5} onChange={handleMainInputs} />
        </div>
        <Button variant="contained" onClick={handleClick}>Pobierz dane</Button>
        {box.map((boxParams) =>
          <div className="decodedInfo" key={boxParams.order_product_id}>
            <strong>{boxParams.name} - {boxParams.price} PLN</strong><br />
            Płeć: {boxParams.g === 1 ? "Mężczyzna" : "Kobieta"}<br />
            Wiek: {boxParams.a}<br />
            {boxParams.r &&
              <>
                Relacja: {Relations.relation.filter(r => r.id === boxParams.r)[0].name}<br />
                Okazja: {Occasions.occasion.filter(o => o.id === boxParams.o)[0].name}<br />
              </>
            }
            Kategorie: <ul>{boxParams.c?.map((c) => (<li key={c}>{Categories.category.filter(cf => cf.id === c)[0].name}</li>))}</ul>
            {boxParams.sC && <>Podkategorie: <ul>{boxParams.sC?.map((sC) => (<li key={sC}>{<>{SubCategories.subCategory.filter(sCf => sCf.id === sC)[0].name}</>}</li>))}</ul></>}
            Słodycze: {boxParams.f === 1 && <strong>Tak (10zł)</strong>}{boxParams.f === 0 && "Nie"}<br />
            Pakowanie w karton: {boxParams.p === 1 && <strong>Tak (20zł)</strong>}{boxParams.p === 0 && "Nie"}<br />
            {boxParams.r &&
              <>Kartka: {boxParams.l === 1 ? <strong>Tak</strong> : "Nie"}<br /></>
            }
          </div>
        )}
      </div>
    </div>
  );
}
