import { useState } from "react";
import { DataGrid } from '@mui/x-data-grid';
import Sidebar from "../../components/sidebar/Sidebar";
import "./occasionList.css";
import Occasions from "../../box_info/Occasions"

export default function OccasionList() {
  const [pageSize, setPageSize] = useState(20);
  const occasions = Occasions.occasion;

  const MONTHS = ["Sty", "Lut", "Mar", "Kwi", "Maj", "Cze", "Lip", "Sie", "Wrz", "Paź", "Lis", "Gru"];

  const columns = [
    { field: "id", headerName: "ID", flex: 0.1 },
    {
      field: "name",
      headerName: "Okazja",
      flex: 0.5,
    },
    {
      field: "ageFrom",
      headerName: "Wiek od",
      flex: 0.1,
    },
    {
      field: "ageTo",
      headerName: "Wiek do",
      flex: 0.1,
    },
    {
      field: "time",
      headerName: "Kiedy wyświetlać?",
      flex: 0.2,
      renderCell: (params) => {
        return (
          <div>
            <span>{params.row.dayFrom} {MONTHS[params.row.monthFrom - 1]}. do {params.row.dayTo} {MONTHS[params.row.monthTo - 1]}.</span>
          </div>
        )
      },
    },
    {
      field: "gender",
      headerName: "Płeć",
      flex: 0.2,
      renderCell: (params) => {
        return (
          <div>
            {params.row.gender.map(g => (g === "male" ? <span key={g}>Mężczyzna; </span> : <span key={g}>Kobieta</span>))}
          </div>
        );
      },
    },
    {
      field: "relations",
      headerName: "ID relacji",
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            {params.row.relations.map(r => (<span key={r}>{r}; </span>))}
          </div>
        );
      },
    }
  ];

  return (
    <div className="container">
      <Sidebar />
      <div className="occasionList">
        <h1 className="occasionTitle">Okazje</h1>
        <div>
          <DataGrid
            rows={occasions}
            disableSelectionOnClick
            autoHeight
            columns={columns}
            getRowId={row => row.id}
            pageSize={pageSize}
            rowsPerPageOptions={[10, 20, 50, 100]}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          />
        </div>
      </div>
    </div>
  );
}
